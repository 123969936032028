import { HTMLAttributes, ElementType, ReactNode, FC } from 'react';
import { useActiveTab } from './tabs.provider';

type Props = HTMLAttributes<HTMLElement> & {
  as?: ElementType | FC<React.PropsWithChildren<unknown>>;
  children: ReactNode;
  controller: string;
  id: string;
  keepMounted?: boolean;
};

export const TabPanel = ({ as = 'section', controller, id, keepMounted = true, ...rest }: Props) => {
  const { activeTab } = useActiveTab();
  const hidden = activeTab !== controller;
  const Component = as;

  if (!keepMounted && hidden) {
    return null;
  }

  return <Component {...rest} role='tabpanel' hidden={hidden} aria-labelledby={controller} />;
};
