import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { useShell } from '@frontend/shell-utils';
import { theme } from '@frontend/theme';
import { usePortal } from '@frontend/design-system';
import { useShellTheme } from './store';
import { TitleBarButton } from './title-bar-button';

const CustomTrafficLightControls = () => {
  const shell = useShell();
  const { t } = useTranslation('base');

  const handleMinimizeWindow = () => {
    shell.emit?.('window:control', 'minimize');
  };

  const handleCloseWindow = () => {
    shell.emit?.('window:control', 'close');
  };

  const handleMaximizeWindow = () => {
    shell.emit?.('window:control', 'maximize');
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-right: ${theme.spacing(1.5)};
        margin-left: auto;
        gap: ${theme.spacing(0.5)};
      `}
    >
      <TitleBarButton label={t('Minimize Window')} onClick={handleMinimizeWindow} icon='minus' />
      <TitleBarButton
        label={t('Maximize Window')}
        onClick={handleMaximizeWindow}
        icon='full-screen-windows'
        aria-label={t('Maximize')}
      />
      <TitleBarButton label={t('Close Window')} onClick={handleCloseWindow} icon='x' />
    </div>
  );
};

export const MacTitleBarContent = () => {
  const { t } = useTranslation('base');

  return (
    <>
      <div id='weave-shell-title-bar-buttons'></div>
      <div
        id='weave-shell-title-bar-content'
        css={css`
          flex-basis: 450px;
          margin-left: auto;
          margin-right: ${theme.spacing(2)};
        `}
      ></div>
      <TitleBarButton
        onClick={() => navigator.clipboard.writeText(window.location.href)}
        label={t('Copy Current URL')}
        showLabelOnHover
        icon='link'
        largeIcon={true}
        about={t('Copy Current URL')}
        css={css`
          margin-right: ${theme.spacing(3)};
        `}
      />
    </>
  );
};

const TitleBarContent = () => {
  const { t } = useTranslation('base');

  return (
    <>
      <div id='weave-shell-title-bar-buttons'></div>
      <TitleBarButton
        onClick={() => navigator.clipboard.writeText(window.location.href)}
        label={t('Copy Current URL')}
        showLabelOnHover
        icon='link'
        largeIcon={true}
        about={t('Copy Current URL')}
        css={css`
          margin-left: ${theme.spacing(2)};
        `}
      />
      <div
        id='weave-shell-title-bar-content'
        css={css`
          flex-basis: 450px;
          margin-left: ${theme.spacing(1)};
        `}
      ></div>

      <CustomTrafficLightControls />
    </>
  );
};

export const ShellTitleBar = () => {
  const { colorObject } = useShellTheme('colorObject');
  const { Portal } = usePortal({
    mountTarget: '#weave-shell-title-bar',
    attributes: {
      style: `
      height: 100%;
      width: 100%;`,
    },
  });
  const { isMac } = useShell();

  return (
    <Portal>
      <motion.div
        css={css`
          display: flex;
          align-items: center;
          min-height: 55px;
          height: 55px;
          width: 100%;
          margin-bottom: auto;
          transition: background 0.6s ease-in-out;
        `}
        initial={{ background: 'linear-gradient(135.55deg, #C2F4F1 25.85%, #89E1DB 107.81%)' }}
        animate={{ background: colorObject.shellColor }}
      >
        {isMac ? <MacTitleBarContent /> : <TitleBarContent />}
      </motion.div>
    </Portal>
  );
};
