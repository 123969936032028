import { useEffect, useMemo, useRef, useState, type PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { MFAMethod, VerifyAuthResponse } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { authnClient } from '@frontend/auth';
import { AuthStorage, getWeaveToken, isWeaveTokenActive, localStorageHelper } from '@frontend/auth-helpers';
import appConfig from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  EmailField,
  FormRow,
  Heading,
  PasswordField,
  PrimaryButton,
  Text,
  TextLink,
  useForm,
  useFormField,
  PasswordProps,
  CheckboxField,
  BaseFieldProps,
  useControlledField,
  Button,
} from '@frontend/design-system';
import { workforceSignin, workforceSigninCallback, PasswordResetOIDC, SignInError, ContentLoader } from '../components';
import { hydraShellService, signInWithPassword } from '../services';
import { getLoginFeatures } from '../services/features-service';
import { MFAForm } from './mfa-form';

const navigate = (url: string) => {
  window.location.href = url;
};

interface Props {
  setIsShowingBGImage: (value: boolean) => void;
}

// const monitorFrameForConsentChallenge = (frame: HTMLIFrameElement) => {
//   return new Promise<string>((resolve, reject) => {
//     const interval = setInterval(() => {
//       try {
//         if (frame.contentWindow?.location.href.includes('consent_challenge')) {
//           const challenge = new URLSearchParams(frame.contentWindow?.location.search).get('consent_challenge');
//           if (challenge) {
//             resolve(challenge);
//             clearInterval(interval);
//           }
//         }
//       } catch (e) {
//         console.log('Error monitoring frame for consent challenge:', e);
//       }
//     }, 50);
//     setTimeout(() => {
//       clearInterval(interval);
//       reject('Consent challenge not found');
//     }, 5000);
//   });
// };

export const LoginFormComponent = ({ setIsShowingBGImage }: Props) => {
  const { t } = useTranslation('SignIn');

  const currentState = useMemo(() => hydraShellService.getCurrentState(), []);
  const frame = useRef<HTMLIFrameElement>(null);
  const [enteredUsername, setEnteredUsername] = useState<string>();
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isCheckingPassword, setIsCheckingPassword] = useState(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [isCheckingConsent, setIsCheckingConsent] = useState(currentState === 'consent');
  const [isLoggingIn, setIsLoggingIn] = useState(() => window.location.href.includes('/sign-in/callback'));
  const [isChecking3rdPartyCookies, setIsChecking3rdPartyCookies] = useState(false);
  const [has3rdPartyCookiesEnabled, setHas3rdPartyCookiesEnabled] = useState(false);
  const [mfaMethods, setMfaMethods] = useState<MFAMethod[]>([]);
  const [challenge, setChallenge] = useState('');
  const [showDisplayNames, setShowDisplayNames] = useState(false);
  const [requestWebauthnRegistration, setRequestWebauthnRegistration] = useState(false);

  const [errorCode, setErrorCode] = useState<number | string>();
  const [isError, setIsError] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [showWebAuthLogin, setShowWebAuthLogin] = useState<boolean>(false);

  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [redirectURL, setRedirectURL] = useState(appConfig.DEFAULT_REDIRECT);

  const [rememberMe, setRememberMe] = useState(false);
  const [loginFeatures, setLoginFeatures] = useState<string[]>([]);

  const rememberedUsername = localStorageHelper.get(AuthStorage.remembered_username);

  const usernameProps = useFormField({
    type: 'email',
    required: true,
    value: rememberedUsername ?? enteredUsername,
  });

  const handleForgotPassword = (userEmail?: string) => {
    setIsShowingBGImage(true);
    setIsResettingPassword(true);
    setEnteredUsername(userEmail ?? '');
  };

  const signInWithPasswordHandler = (username: string, password: string) => {
    setIsCheckingPassword(true);
    setIsError(false);
    //if no login challenge is in url, then redirect user back to portal link
    const loginChallenge = hydraShellService.getLoginChallenge();
    if (loginChallenge === '') {
      setTimeout(() => {
        navigate(appConfig.DEFAULT_REDIRECT);
      }, 500);
    }
    // send username and password to backend
    // if successful, redirect to the requesting application
    // if unsuccessful, set error message
    return signInWithPassword(username, password, requestWebauthnRegistration)
      .then((res: VerifyAuthResponse) => {
        if (res && typeof res === 'object' && 'token' in res) {
          if (res.pwned) {
            setIsShowingBGImage(true);
            setErrorCode('pawnedPassword');
            setIsError(true);
            setIsResettingPassword(true);
            setIsCheckingPassword(false);
            setEnteredUsername(username);
            return;
          }
          if (res.mfa && res.mfa.method && res.mfa.method.length > 0) {
            setChallenge(res?.challenge ?? '');
            setMfaEnabled(true);
            res.mfa.method.forEach((method) => {
              setMfaMethods((prevMethods) => [...prevMethods, method]);
            });
            if (!res.mfa.codeSent) {
              setShowDisplayNames(true);
            }
            return;
          }
          if (res.token) {
            handleAcceptOAuth2Flow(res.token ?? '');
          } else {
            throw new Error('No token returned');
          }
        }
      })
      .catch(handleFailedLogin);
  };

  const handleFailedLogin = () => {
    setIsCheckingPassword(false);
    setIsLoggingIn(false);
    setIsError(true);
    // TODO: if error other than 401, send error to sentry
    setLoginAttempts(loginAttempts + 1);
    if (loginAttempts > 2) {
      setErrorCode(402);
    } else {
      setErrorCode(401);
    }
  };

  const handleWebAuthnSignInResponse = (res: VerifyAuthResponse) => {
    if (res.token) {
      handleAcceptOAuth2Flow(res.token);
    } else {
      handleFailedLogin();
    }
  };

  const handleWebAuthnError = (_: Error) => {
    setIsError(true);
    setErrorCode('webauthnError');
    // TODO: update sign-in-error component to support a debug message for helping users and support
  };

  const handleAcceptOAuth2Flow = (weaveJWT: string) => {
    setIsCheckingPassword(false);
    setIsLoggingIn(true);
    const currentState = hydraShellService.getCurrentState();

    switch (currentState) {
      case 'login':
        hydraShellService
          .acceptOAuth2Flow(weaveJWT)
          .then((res) => {
            if (!!res.redirectTo) {
              if (rememberMe) {
                localStorageHelper.create(AuthStorage.remembered_username, usernameProps.value);
              } else {
                localStorageHelper.delete(AuthStorage.remembered_username);
              }
              // if (has3rdPartyCookiesEnabled) {
              //   handleConsentWithIframe(weaveJWT, res.redirectTo);
              // } else {
              //   navigate(res.redirectTo);
              // }
              console.info('has3rdPartyCookiesEnabled', has3rdPartyCookiesEnabled);
              navigate(res.redirectTo);
            }
          })
          .catch((err: Error) => {
            if (err.message === 'login request was cancelled') {
              console.warn('user logging in does not match the previous user, we need to get a new login challenge');
              navigate(redirectURL || appConfig.DEFAULT_REDIRECT);
            } else {
              throw new Error('Error accepting login request');
            }
          });
        break;
      default:
        // If we are in this state, then we don't know what client initialized the flow so send them to the default redirect
        setIsLoggingIn(true);
        navigate(appConfig.DEFAULT_REDIRECT);
        break;
    }
  };

  useEffect(() => {
    setIsCheckingStatus(true);
    const isAuthed = isWeaveTokenActive();
    const weaveJWT = getWeaveToken() || ''; // if already authenticated then this will never be undefined
    if (currentState === 'login') {
      handleInitializeOAuth2Flow(isAuthed, weaveJWT);
    }
    if (currentState === 'consent') {
      handleConsentQueryParam(weaveJWT);
    }
    if (currentState === 'logout') {
      handleLogoutFlow(weaveJWT);
    }
    if (currentState === 'none') {
      setIsCheckingStatus(false);
    }
    if (window.location.href.includes('/sign-in/callback')) {
      handleWorkforceCallback();
    }
  }, [currentState]);

  useEffect(() => {
    const isAuthed = isWeaveTokenActive();
    setShowWebAuthLogin(loginFeatures.includes('webauthn') && !isAuthed);
  }, [loginFeatures]);

  useEffect(() => {
    if (rememberedUsername) setRememberMe(true);

    // initialize login features
    getLoginFeatures()
      .then((features) => {
        setLoginFeatures(features);
      })
      .catch((_) => {
        setLoginFeatures([]);
      });

    setIsChecking3rdPartyCookies(true);
    const iframe3rdpartycookiecheck = document.createElement('iframe');
    //this website just post-messages whether 3rd party cookies are enabled or not.
    //TODO: We should host our own version of this
    iframe3rdpartycookiecheck.src = 'https://mindmup.github.io/3rdpartycookiecheck/start.html';
    iframe3rdpartycookiecheck.style.display = 'none';
    iframe3rdpartycookiecheck.id = 'iframe3rdpartycookiecheck';
    document.body.appendChild(iframe3rdpartycookiecheck);
    const receiveMessage = (evt: MessageEvent<any>) => {
      setIsChecking3rdPartyCookies(false);
      if (evt.data === 'MM:3PCunsupported') {
        console.info('3rd party cookies are disabled');
        setHas3rdPartyCookiesEnabled(false);
      } else if (evt.data === 'MM:3PCsupported') {
        console.info('3rd party cookies are enabled');
        setHas3rdPartyCookiesEnabled(true);
      }
    };
    window.addEventListener('message', receiveMessage);
    //if we don't get a response in 1 second, we don't wait any longer.
    setTimeout(() => {
      setIsChecking3rdPartyCookies(false);
    }, 1000);
    return () => {
      const node = document.getElementById('iframe3rdpartycookiecheck');
      if (node) {
        document.body.removeChild(node);
      }
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const handleInitializeOAuth2Flow = (isAuthed: boolean, weaveJWT: string) => {
    let localRedirectURL: string | undefined;
    hydraShellService
      .initializeOAuth2Flow()
      .then((res) => {
        setIsCheckingStatus(false);

        // Store redirectURL for later use in the catch block
        if (res.requestUrl) {
          localRedirectURL = getRedirectURL(res.requestUrl);
          setRedirectURL(getRedirectURL(res.requestUrl) || appConfig.DEFAULT_REDIRECT);
        }

        // if we are already authenticated, we can accept the OAuth2 flow
        if (isAuthed) {
          setIsLoggingIn(true);
          return hydraShellService
            .acceptOAuth2Flow(weaveJWT)
            .then((res) => {
              navigate(res.redirectTo || localRedirectURL || appConfig.DEFAULT_REDIRECT);
            })
            .catch((err: Error) => {
              if (err.message === 'login request was cancelled') {
                console.warn('user logging in does not match the previous user, we need to get a new login challenge');
                navigate(localRedirectURL || appConfig.DEFAULT_REDIRECT);
              } else {
                throw new Error('Error accepting login request');
              }
            });
        }
        return {};
      })
      .catch((err: Error) => {
        if (err.message === 'login request was cancelled') {
          console.warn('user logging in does not match the previous user, we need to get a new login challenge');
        }
        setIsCheckingStatus(false);
        // alerts.error(t('Error initializing OAuth2 flow'));
        navigate(redirectURL || appConfig.DEFAULT_REDIRECT);
      });
  };

  const handleConsentQueryParam = (weaveJWT: string) => {
    let redirectURL: string | undefined;
    setIsCheckingConsent(true);
    hydraShellService
      .initializeConsentFlow(weaveJWT)
      .then((res) => {
        setIsCheckingStatus(false);
        // setIsCheckingConsent(false);  intentionally not turning off consent state here
        // Store redirectURL for later use in the catch block
        if (res.requestUrl) {
          redirectURL = getRedirectURL(res.requestUrl);
        }
        return hydraShellService.acceptConsentFlow(weaveJWT, res.scope || [], res.client?.clientId);
      })
      .catch(() => {
        setIsCheckingStatus(false);
        // setIsCheckingConsent(false);  intentionally not turning off consent state here
        // Use redirectURL in the catch block
        navigate(redirectURL || appConfig.DEFAULT_REDIRECT);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleConsentWithIframe = (weaveJWT: string, redirectTo: string) => {
  //   let finalRedirectUrl = redirectTo;
  //   return Promise.resolve()
  //     .then(() => {
  //       // Store redirectURL for later use in the catch block
  //       if (finalRedirectUrl && frame.current) {
  //         frame.current.src = redirectTo;
  //         setIsCheckingConsent(true);
  //         return monitorFrameForConsentChallenge(frame.current)
  //           .then((challenge) => {
  //             hydraShellService
  //               .initializeConsentFlow(weaveJWT, challenge)
  //               .then((res) => {
  //                 setIsCheckingStatus(false);
  //                 // Store redirectURL for later use in the catch block
  //                 if (res.requestUrl) {
  //                   finalRedirectUrl = getRedirectURL(res.requestUrl) || appConfig.DEFAULT_REDIRECT;
  //                 }
  //                 return hydraShellService.acceptConsentFlow(
  //                   weaveJWT,
  //                   res.scope || [],
  //                   res.client?.clientId,
  //                   challenge
  //                 );
  //               })
  //               .catch(() => {
  //                 setIsCheckingStatus(false);
  //                 throw new Error('Error initializing consent flow in hydra shell service');
  //               });
  //           })
  //           .catch(() => {
  //             throw new Error('Error initializing consent from iframe contentwindow');
  //           });
  //       } else {
  //         throw new Error('Error initializing consent. no request url or no frame');
  //       }
  //     })
  //     .catch((err: Error) => {
  //       sentry.error({
  //         error: err && 'message' in err ? err.message : 'Error initializing consent flow',
  //         topic: 'consent',
  //       });
  //       navigate(finalRedirectUrl || appConfig.DEFAULT_REDIRECT);
  //     });
  // };

  const getRedirectURL = (requestUrl: string): string | undefined => {
    try {
      // Parse the requestUrl using the URL class
      const url = new URL(requestUrl);

      // Extract the value of the 'redirect_uri' query parameter
      const redirectUriParam = url.searchParams.get('redirect_uri');

      // Return the decoded value of the parameter
      return redirectUriParam ? getHostFromURL(decodeURIComponent(redirectUriParam)) : undefined;
    } catch (error) {
      console.error('Error parsing requestUrl:', error);
      sentry.error({
        error: 'Error parsing requestUrl',
        topic: 'auth',
      });
      return;
    }
  };

  const getHostFromURL = (inUrl: string): string | undefined => {
    try {
      // Parse the requestUrl using the URL class
      const url = new URL(inUrl);

      // Extract and return the protocol and host property
      return `${url.protocol}//${url.host}`;
    } catch (error) {
      console.error('Error parsing requestUrl:', error);
      return;
    }
  };

  const handleLogoutFlow = async (weaveJWT: string) => {
    hydraShellService
      .initializeLogoutFlow(weaveJWT)
      .then(() => {
        return authnClient.signOut();
      })
      .then(() => {
        return hydraShellService.acceptLogoutFlow(weaveJWT);
      })
      .catch(() => {
        authnClient.signOut().finally(() => {
          setIsCheckingStatus(false);
          const rememberedUsername = localStorageHelper.get(AuthStorage.remembered_username);

          Object.keys(localStorageHelper).forEach((key) => {
            if (key !== rememberedUsername) {
              localStorage.removeItem(key);
            }
          });
          navigate(appConfig.DEFAULT_REDIRECT);
        });
      })
      .finally(() => setIsCheckingStatus(false));
  };

  const handleWorkforceCallback = () => {
    workforceSigninCallback()
      .then((clientTokens) => {
        setIsCheckingStatus(false);
        return handleInitializeOAuth2Flow(true, clientTokens.weaveToken || '');
      })
      .catch(() => {
        setIsCheckingStatus(false);
        // everything else failed so redirect to the default redirect
        navigate(appConfig.DEFAULT_REDIRECT);
      });
  };

  if (isLoggingIn || isCheckingConsent) {
    return (
      <>
        <FormWrap>
          <ContentLoader message={t('Logging in...')} show />
        </FormWrap>
        <iframe ref={frame} style={{ display: 'none' }} />
      </>
    );
  }

  if (isResettingPassword) {
    return (
      <>
        <PasswordResetOIDC
          initialUsername={enteredUsername}
          onBack={(email) => {
            setEnteredUsername(email);
            setIsShowingBGImage(false);
            setIsError(false);
            setIsResettingPassword(false);
          }}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 15%;
            min-width: 300px;
            max-width: 400px;
          `}
        >
          {isError ? <SignInError errorCode={errorCode} onErrorHide={setIsError} /> : null}
        </div>
      </>
    );
  }

  return (
    <>
      <iframe ref={frame} style={{ display: 'none' }} />
      {mfaEnabled ? (
        <FormWrap>
          <MFAForm
            mfaMethods={mfaMethods}
            onHandleMFAViewToggle={setMfaEnabled}
            handleSignIn={handleAcceptOAuth2Flow}
            challenge={challenge}
            showDisplayNames={showDisplayNames}
            onToggleDisplayNames={setShowDisplayNames}
            registerWebauthn={requestWebauthnRegistration}
            rememberMe={rememberMe}
          />
        </FormWrap>
      ) : (
        <>
          <FormWrap>
            <Heading>{t('Welcome back')}</Heading>
            <Text>{t('Log in to your Weave account')}</Text>
            <LoginFormInner
              isCheckingPassword={isCheckingPassword}
              isCheckingStatus={isCheckingStatus || isChecking3rdPartyCookies}
              onSubmitPassword={signInWithPasswordHandler}
              onSubmitSSO={workforceSignin}
              onClickForgotPassword={(userEmail) => handleForgotPassword(userEmail)}
              shouldFocus={() => !showWebAuthLogin}
              rememberMe={rememberMe}
              setRememberMe={setRememberMe}
              usernameProps={usernameProps}
              showWebAuthLogin={showWebAuthLogin}
              requestWebauthnRegistration={requestWebauthnRegistration}
              setRequestWebauthnRegistration={setRequestWebauthnRegistration}
              onHandleWebauthnSignIn={handleWebAuthnSignInResponse}
              onHandleWebauthnError={handleWebAuthnError}
            />
          </FormWrap>
        </>
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 15%;
          min-width: 300px;
          max-width: 400px;
        `}
      >
        {isError ? <SignInError errorCode={errorCode} onErrorHide={setIsError} /> : null}
      </div>
    </>
  );
};

type FormProps = {
  isCheckingPassword: boolean;
  isCheckingStatus: boolean;
  shouldFocus: () => boolean;
  onClickForgotPassword: (username?: string) => void;
  onSubmitSSO: (username: string) => void;
  onSubmitPassword: (username: string, password: string) => void;
  rememberMe: boolean;
  setRememberMe: (value: boolean) => void;
  usernameProps: BaseFieldProps<string>;
  showWebAuthLogin: boolean;
  requestWebauthnRegistration: boolean;
  setRequestWebauthnRegistration: (value: boolean) => void;
  onHandleWebauthnSignIn: (res: VerifyAuthResponse) => void;
  onHandleWebauthnError: (err: Error) => void;
};
const LoginFormInner = ({
  isCheckingPassword,
  isCheckingStatus,
  onSubmitPassword,
  onSubmitSSO,
  onClickForgotPassword,
  shouldFocus,
  rememberMe,
  setRememberMe,
  usernameProps,
  showWebAuthLogin,
  requestWebauthnRegistration,
  setRequestWebauthnRegistration,
  onHandleWebauthnSignIn,
  onHandleWebauthnError,
}: FormProps) => {
  const { t } = useTranslation('SignIn');
  const passwordProps = useFormField({ type: 'password', required: true, minChars: 1 });
  const usernameRef = useRef<HTMLDivElement>(null);
  const isSSO = usernameProps.value.endsWith('@getweave.com');
  const [autofocusAlreadyRun, setAutofocusAlreadyRun] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  //autofocus on the username field
  useEffect(() => {
    if (usernameRef?.current && shouldFocus()) {
      //setting a timeout here to allow the browser enough time to attempt an autocomplete before we focus
      const autoFocusTimer = setTimeout(() => {
        if (shouldFocus() && !autofocusAlreadyRun) {
          usernameRef?.current?.querySelector('input')?.focus();
          setAutofocusAlreadyRun(true);
        }
      }, 300);
      return () => clearTimeout(autoFocusTimer);
    }
    return () => {};
  }, [usernameRef, shouldFocus]);

  const { formProps } = useForm({
    fields: {},
    onSubmit: () =>
      isSSO ? onSubmitSSO(usernameProps.value) : onSubmitPassword(usernameProps.value, passwordProps.value),
  });

  const checkboxField = useControlledField({
    onChange: (value) => {
      setRememberMe(value);
    },
    type: 'checkbox',
    value: rememberMe,
  });

  const webauthnRegFormProps = useControlledField({
    onChange: () => setRequestWebauthnRegistration(!requestWebauthnRegistration),
    type: 'checkbox',
    value: requestWebauthnRegistration,
  });

  const isSubmitButtonEnabled =
    !isCheckingPassword && !isCheckingStatus && (isSSO || (!passwordProps.error && !usernameProps.error));

  //autofocus on the username field
  useEffect(() => {
    if (buttonRef?.current) {
      setTimeout(() => {
        buttonRef?.current?.focus();
      }, 300);
    }
  }, [buttonRef]);

  const handleBeginWebauthnLogin = async () => {
    authnClient.changeAuthMethod('legacy');
    return authnClient
      .signIn({ useWebauthn: true })
      .then((res: VerifyAuthResponse) => onHandleWebauthnSignIn(res))
      .catch((err) => onHandleWebauthnError(err));
  };
  return (
    <form {...formProps}>
      <FormRow
        css={css`
          text-align: initial;
          margin: ${theme.spacing(4, 0)};
        `}
      >
        <EmailField ref={usernameRef} name='username' {...usernameProps} label={t('Email')} />
      </FormRow>

      {!isSSO && (
        <FormRow
          css={css`
            text-align: initial;
            margin: ${theme.spacing(0, 0, 1)};
          `}
        >
          <PasswordField
            active={passwordProps.active}
            aria-invalid={passwordProps['aria-invalid']}
            error={passwordProps.error}
            id={passwordProps.id}
            label={t('Password')}
            minChars={(passwordProps as PasswordProps).minChars}
            name='password'
            onBlur={() => passwordProps.onBlur()}
            onChange={passwordProps.onChange}
            onFocus={() => passwordProps.onFocus()}
            required={passwordProps.required}
            touched={passwordProps.touched}
            value={passwordProps.value.toString()}
          />
        </FormRow>
      )}

      <TextLink
        css={css`
          display: flex;
          margin: ${theme.spacing(0, 0, 4)};
          cursor: pointer;
          text-align: left;
          width: 100%;
        `}
        onClick={() => onClickForgotPassword(usernameProps.value)}
      >
        {t('Forgot Password?')}
      </TextLink>

      {!isSSO && (
        <FormRow>
          <CheckboxField name='Remember Me' {...checkboxField} label={t('Remember Me')} />
        </FormRow>
      )}

      {showWebAuthLogin && (
        <FormRow>
          <CheckboxField
            name={'request-webauthn-registration'}
            {...webauthnRegFormProps}
            label={t('Set up new passkey')}
            value={requestWebauthnRegistration}
          />
        </FormRow>
      )}

      <PrimaryButton disabled={!isSubmitButtonEnabled} size='large' type='submit' css={{ width: '100%' }}>
        {isCheckingPassword
          ? t('Verifying...')
          : isCheckingStatus
          ? t('Checking Status...')
          : isSSO
          ? t('Proceed with SSO')
          : t('Log In')}
      </PrimaryButton>

      {showWebAuthLogin && (
        <>
          <div
            css={css`
              display: flex;
              align-items: center;
              text-align: center;
              margin: ${theme.spacing(2, 0)};

              &::before,
              &::after {
                content: '';
                flex: 1;
                height: 1px;
                background: ${theme.colors.neutral50};
              }

              &::before {
                margin-right: ${theme.spacing(1)};
              }

              &::after {
                margin-left: ${theme.spacing(1)};
              }
            `}
          >
            <span
              css={css`
                padding: ${theme.spacing(0, 2)};
                color: ${theme.colors.neutral30};
              `}
            >
              Or
            </span>
          </div>

          <Button
            variant='secondary'
            size='large'
            css={{ width: '100%' }}
            ref={buttonRef}
            onClick={handleBeginWebauthnLogin}
          >
            {t('Log In with Passkey')}
          </Button>
        </>
      )}
    </form>
  );
};

const FormWrap = ({ children }: PropsWithChildren) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin: 0 15%;
        min-width: 300px;
        max-width: 400px;
        @media screen and (max-width: ${breakpoints.small.max}px) {
          width: 90%;
          margin: 0 5%;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        {children}
      </div>
    </div>
  );
};
