import {
  GetLoginFeaturesResponse,
  LoginFeatures,
} from '@weave/schema-gen-ts/dist/schemas/login-features/v1/service.pb';
import { AuthStorage, localStorageHelper } from '@frontend/auth-helpers';
import { APIfetchNoAuth } from './fetch-wrapper';

let loginFeatures: string[] = [];

// old param specific url
const webauthnParam = 'webauthn';
const localStorageFeatureKey = 'login_features';

export const getLoginFeatures = async (): Promise<string[]> => {
  const fingerprint = localStorageHelper.get(AuthStorage.sign_in_fingerprint);
  if (loginFeatures.length > 0) {
    return loginFeatures;
  }
  // uniquely combine features from URL and local storage
  loginFeatures = Array.from(new Set([...featuresFromURL(), ...featuresFromLocalStorage()]));

  return LoginFeatures.GetLoginFeatures(
    APIfetchNoAuth() as (url: string, reqInit: RequestInit) => Promise<GetLoginFeaturesResponse>,
    {
      fingerprint,
    }
  )
    .then((response) => {
      // uniquely combine features from URL, local storage and response
      loginFeatures = Array.from(new Set([...loginFeatures, ...(response.features || [])]));
      // filter out any features that start with `!`
      loginFeatures = loginFeatures.filter((feature) => !feature.startsWith('!'));
      return loginFeatures;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

function featuresFromURL(): string[] {
  const url = new URL(window.location.href);
  const isWebauthn = url.searchParams.get(webauthnParam); // support for the old specific param
  const rawFeatures = url.searchParams.get('_f');
  const features = rawFeatures ? rawFeatures.split(',') : [];
  if (isWebauthn) {
    features.push('webauthn');
  }
  return features;
}

function featuresFromLocalStorage(): string[] {
  const rawFeatures = localStorageHelper.get(localStorageFeatureKey);
  return rawFeatures ? rawFeatures.split(',') : [];
}
